<script>
export default {
  name: 'ABTestComponent',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentOption: 'a',
      interval: undefined
    }
  },
  created() {
    if (!window.a_b_test[this.name]) {
      window.a_b_test[this.name] = 'a'
    }

    this.updateOption()

    this.$bus.on('update-a-b-test', this.updateOption)
    this.interval = setInterval(this.updateOption, 2000)
  },
  beforeUnmount() {
    this.$bus.off('update-a-b-test', this.updateOption)
    clearInterval(this.interval)
  },
  methods: {
    updateOption() {
      const newOption = window.a_b_test[this.name].toLowerCase()

      if (this.currentOption !== newOption) {
        this.currentOption = newOption
        this.$bus.emit('changed-a-b-test', {
          name: this.name,
          value: this.currentOption
        })
      }
    }
  },
  render() {
    if (this.currentOption === 'c' && this.$slots.c) {
      return this.$slots.c()
    } else if (this.currentOption === 'b' && this.$slots.b) {
      return this.$slots.b()
    } else if (this.$slots.a) {
      return this.$slots.a()
    } else if (this.$slots.default) {
      return this.$slots.default()
    }

    return undefined
  }
}
</script>
